<script>
import Layout from "../../layouts/main";
import appConfig from "@/app.config";
import axios from 'axios';

export default {
    data: function () {
		return {
            freecompany: []
		}
	},
	created: async function () {
		await this.fetchFCData()
	},
	methods: {
		fetchFCData: function () {
            const uri = process.env.VUE_APP_XIVAPI + '/freecompany/' + this.$route.params.fcid
            this.debugLevel('URI loaded: ' + uri)

			return axios.get(uri).then((response) => {
				this.freecompany = response.data
                document.title = response.data.name + ' | XIVDATA';
				this.debugLevel(['FreeCompany data successfully loaded: ', this.freecompany])
			}).catch((error) => {
				this.debugLevel('FreeCompany data failed to load with error: ' + error)
			})
		},

        debugLevel: function (value, level = 2) {
            const values = [];
            Array.isArray(value) ? value.forEach(element => values.push(element)) : values[0] = value;
            switch (level) {
                case 1:
                if (process.env.NODE_ENV == 'test' || process.env.NODE_ENV == 'development') {
                    values.forEach(element => console.log(element));
                }
                break;
                case 2:
                if (process.env.NODE_ENV == 'development') {
                    values.forEach(element => console.log(element));
                  }
                break;
                default:
                    values.forEach(element => console.log(element));
                break;
            }
        },
	},
  page: {
    title: "FC Name",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: {
    Layout
  },
};
</script>
<template>
    <Layout>
        <div class="row align-items-center">
            <div class="col-sm-6">
                <div class="page-title-box">
                    <h4 class="font-size-18">{{ freecompany.name }}</h4>
                    <ol class="breadcrumb mb-0">
                        <li class="breadcrumb-item">XIVDATA</li>
                        <li class="breadcrumb-item">{{ $t('messages.freecompany') }}</li>
                        <li class="breadcrumb-item active">{{ freecompany.name }}</li>
                    </ol>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-2 col-lg-6 col-xl-2">
                                <div class="card">
                                    <img :src="freecompany.crest" class="card-img-top img-fluid rounded" />
                                    <div class="mb-2"></div>
                                    <div class="nav flex-column nav-pills">
                                        <router-link :to="{path: '/freecompany/' + freecompany.id}" class="nav-link"><i class="fas fa-home"></i> {{ $t('freecompany.info_button') }}</router-link>
                                        <router-link :to="{path: '/freecompany/' + freecompany.id + '/member'}" class="nav-link"><i class="far fa-user"></i> {{ $t('freecompany.member_button') }}</router-link>
                                        <router-link :to="{path: '/freecompany/' + freecompany.id + '/apply'}" class="nav-link"><i class="far fa-envelope"></i> {{ $t('freecompany.apply_button') }}</router-link>
                                        <router-link :to="{path: '/freecompany/' + freecompany.id + '/screenshots'}" class="nav-link"><i class="fas fa-horse"></i> {{ $t('freecompany.screenshots_button') }}</router-link>
                                        <router-link :to="{path: '/freecompany/' + freecompany.id + '/bank'}" class="nav-link"><i class="fas fa-cat"></i> {{ $t('freecompany.bank_button') }}</router-link>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-2 col-lg-6 col-xl-10">
                                <h4>{{ $t('freecompany.memberlist') }}</h4>
                                <hr />
                                <div class="card-deck">
                                    <div v-for="member in freecompany.member" :key="member.char_id" class="card">
                                        <img :src="member.avatar" class="card-img-top" />
                                        <div class="card-body">
                                            <ul class="list-group list-group-flush">
                                                <li class="list-group-item"><a :href="'/character/' + member.char_id" class="text-reset">{{ member.name }}</a></li>
                                                <li class="list-group-item"><img :src="member.rankicon" class="img-fluid" /> {{ member.rank }}</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>